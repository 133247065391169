<template>
  <div></div>
</template>

<script>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { STLExporter } from "three/examples/jsm/exporters/STLExporter";
import { GUI } from "three/examples/jsm/libs/lil-gui.module.min.js";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader.js";
import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils.js";

let scene, camera, renderer, exporter, mesh, mergedMesh, link;
const objectsMapFiles = new Map([
  [0, "000.stl"],
  [1, "001.stl"],
  [2, "002.stl"],
  [3, "003.stl"],
  [4, "004.stl"],
  [6, "006.stl"],
  [7, "007.stl"],
  [8, "008.stl"],
  [9, "009.stl"],
  [10, "010.stl"],
  [11, "011.stl"],
  [182, "182.stl"],
  [183, "183.stl"],
  [184, "184.stl"],
  [185, "185.stl"],
  [186, "186.stl"],
  [187, "187.stl"],
  [188, "188.stl"],
  [189, "189.stl"],
  [190, "190.stl"],
  [194, "194.stl"],
  [195, "195.stl"],
  [196, "196.stl"],
  [197, "197.stl"],
  [198, "198.stl"],
  [199, "199.stl"],
  [200, "200.stl"],
  [201, "201.stl"],
  [202, "202.stl"],
  [244, "244.stl"],
  [245, "245.stl"],
  [246, "246.stl"],
  [247, "247.stl"],
  [248, "248.stl"],
  [249, "249.stl"],
]);
// shapeObjectsList will store the loaded objects
var shapeObjectsList = [];

// shapeObjectsMap will store the key and index
var shapeObjectsMap = new Map();

// spawnedObjects will store the spawned objects
var spawnedObjects = [];

// spawnedScale is in the GUI so it has to be initially 1.0
var spawnedScale = 1.0;


export default {
  props: ["img_id"],

  data() {
    return {
      // 
      // oneShapeArray: [
      //   51, 51, 51, 51, 51, 51, 0, 3, 0, 51, 51, 2, 51, 2, 51, 51, 4, 2, 4, 51,
      //   51, 4, 51, 4, 51,
      // ],
      // oneShapeRotationArray: [
      //   90.0, 90.0, 180.0, 90.0, 90.0, 270.0, 90.0, 90.0, 0.0, 270.0, 90.0, 0.0,
      //   180.0, 270.0, 270.0, 0.0, 180.0, 0.0, 180.0, 0.0, 180.0, 0.0, 90.0, 0.0,
      //   90.0,
      // ],
      // oneShapeArray is a 1D array, will contain the 25 IDs of each shape at each iteration below
      oneShapeArray: [],
      // oneShapeRotationArray is a 1D array, will contain the 25 rotations of each shape at each iteration below
      oneShapeRotationArray: [],
      // allShapesArray is a 2D array, will contain each shape 25 IDs
      allShapesArray: [],
      // allShapesRotationsArray is a 2D array, will contain each shape 25 rotations
      allShapesRotationsArray: [],
      // oneShapeColorArray is a 1D array, will contain the 25 colors of each shape at each iteration below
      oneShapeColorArray: [],
      // allShapesColorArray is a 2D array, will contain each shape 25 colors
      allShapesColorArray: [],
      // type stores the type of the shape
      // default, xMode, cubeMode, CreativeHorizontalMode, creativeVerticalMode, verticalMode, horizontalMode, advanced_1 to advanced_4
      type: "",
    };
  },
  mounted() {
    // payload for the API api/advanced/get/json, api/evaluations/get/json
    const payload = {
      evaluation_id: this.$route.params.img_id,
      type: this.$route.params.type,
      advanced_id: this.$route.params.advanced_id,
      lesson_id: this.$route.params.lesson_id,
      user_id: this.$route.params.user_id,
    };

    const actionName = payload.advanced_id
      ? "groups/handlerThreeDataAdvanced"
      : "groups/handlerThreeDataEvaluation";

    this.$store
      .dispatch(actionName, payload)
      .then((res) => {
        // Store the received JSON
        let jsonAllShapes = res.evaluation_json;

        // Here we check if there was no type
        try {
          this.type = jsonAllShapes[0].object.type;
          if (!this.type){
            throw ErrorEvent("No type found");
          }
        } catch (err) {
          // if there is no type we make the type Undef so it is treated as default type
          this.type = "Undef";
        }

        let shapesData = jsonAllShapes[0].object.data;
        // Here we loop on each element in the JSON to get all the shapes
        shapesData.forEach((element) => {

          // Here we loop on each shape in the JSON to get all the ids, rotations and colors
          element.ids.forEach((element) => {
            this.oneShapeArray.push(element.id);
            this.oneShapeRotationArray.push(element.rotation);
            // Some shapes doesn't have color
            if (element.color){
              this.oneShapeColorArray.push(element.color);
            }
          });

          // Filling the 2D arrays
          this.allShapesArray.push(this.oneShapeArray);
          this.allShapesRotationsArray.push(this.oneShapeRotationArray);
          this.allShapesColorArray.push(this.oneShapeColorArray);

          // Emptying the arrays for the next iteration
          this.oneShapeArray = [];
          this.oneShapeRotationArray = [];
          this.oneShapeColorArray = [];
        });

        console.log("type: ", this.type);
        console.log("all Shapes Array: ", this.allShapesArray);
        console.log("all Shapes Rotations Array: ", this.allShapesRotationsArray);
        console.log("all Shapes Color Array: ", this.allShapesColorArray);
      })
      .finally(() => {
        this.init();
        this.loadallShapesArray();
        this.animate();
      });
  },
  methods: {
    init() {
      // Initializing the scene/camera/lights/ground/controls
      camera = new THREE.PerspectiveCamera(
        65,
        window.innerWidth / window.innerHeight,
        5,
        10000
      );
      camera.position.set(0, 180, 380);

      scene = new THREE.Scene();
      scene.background = new THREE.Color(0xa0a0a0);
      // scene.fog = new THREE.Fog( 0xa0a0a0, 4, 20 );

      exporter = new STLExporter();

      // lights
      const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444, 3);
      hemiLight.position.set(0, 20, 0);
      scene.add(hemiLight);

      const directionalLight = new THREE.DirectionalLight(0xffffff, 3);
      directionalLight.position.set(0, 20, 10);
      directionalLight.castShadow = true;
      directionalLight.shadow.camera.top = 2;
      directionalLight.shadow.camera.bottom = -2;
      directionalLight.shadow.camera.left = -2;
      directionalLight.shadow.camera.right = 2;
      scene.add(directionalLight);

      // ground
      let groundSize = 500;
      // Make the ground larger in case of advanced_4 (8 objects)
      if (this.type == "advanced_4"){
        groundSize = 1200;
        camera.position.set(0, 180, 600);
      }
      else if (this.type == "advanced_3"){
        camera.position.set(0, 350, 550);
      }
      const ground = new THREE.Mesh(
        new THREE.PlaneGeometry(groundSize, groundSize),
        new THREE.MeshPhongMaterial({ color: 0xbbbbbb, depthWrite: false })
      );
      ground.rotation.x = -Math.PI / 2;
      ground.receiveShadow = true;
      scene.add(ground);

      const grid = new THREE.GridHelper(groundSize, groundSize/30, 0x000000, 0x000000);
      grid.material.opacity = 0.2;
      grid.material.transparent = true;
      scene.add(grid);

      renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.shadowMap.enabled = true;
      document.body.appendChild(renderer.domElement);

      const controls = new OrbitControls(camera, renderer.domElement);
      controls.target.set(0, 0.5, 0);
      controls.update();

      window.addEventListener("resize", this.onWindowResize);

      let params = {
        exportASCII: this.exportASCII,
        exportBinary: this.exportBinary,
        modifyScale: 1.0,
        removeObjects: this.removeObjects,
        spawnObjects: this.spawnObjects,
      };

      // GUI elements
      const gui = new GUI({ width: 310 });

      gui.add(params, "exportASCII").name("Export STL (ASCII)");
      gui.add(params, "exportBinary").name("Export STL (Binary)");
      gui
        .add(params, "modifyScale", 0.01, 3, 0.01)
        .listen()
        .onChange((weight) => this.changeScale(weight))
        .name("Modify scale");
      gui.add(params, "spawnObjects").name("Spawn Objects");
      gui.add(params, "removeObjects").name("Remove Objects");
      gui.open();

      link = document.createElement("a");
      link.style.display = "none";
      document.body.appendChild(link);
    },

    loadallShapesArray() {
      // Load each object into shapeObjectsList
      const stlLoader = new STLLoader();
      var index = 0;
      objectsMapFiles.forEach((value, key) => {
        stlLoader.load("/models-3d/" + value, (stlScene) => {
          shapeObjectsMap.set(key, index);
          shapeObjectsList.push(stlScene);
          // After loading each object, spawn them
          if (index == objectsMapFiles.size - 1) {
            this.spawnObjects();
          }
          index += 1;
        });
      });
    },

    spawnObjects() {
      const defaultMaterial = new THREE.MeshPhongMaterial({ color: 0x777777 });
      const materials = [];

      // Here we loop on each shape in allShapesArray
      this.allShapesArray.forEach((objectRowIDs, shapeIndex) => {

        // Here we loop on each object in the shape
        // The objectIndex will range from 0 to 24
        objectRowIDs.forEach((objectID, objectIndex) => {
          // the object is in the map
          if (shapeObjectsMap.has(objectID)) {
            // We get a clone from the loaded objects
            var tempObject =
              shapeObjectsList[shapeObjectsMap.get(objectID)].clone();

            // We then scale the object
            // as spawnedScale is in the GUI so it has to be initially 1.0
            // we multiply the shapes with 0.1 to make them smaller
            // we add 0.001 to help with 3D printing as the shapes are separated
            const factor = 0.1;
            tempObject.scale(
              spawnedScale * factor + 0.001,
              spawnedScale * factor + 0.001,
              spawnedScale * factor + 0.001
            );

            // and we rotate the object
            tempObject.rotateZ(
              (this.allShapesRotationsArray[shapeIndex][objectIndex] / 180) * (22 / 7)
            );

            // then place the object at its location
            if (this.type == "advanced_2" || this.type == "advanced_4") {
              // [] [] or [] [] [] [] [] [] [] []
              tempObject.translate(
                100 * (objectIndex % 5) + shapeIndex * 500,
                -100 * parseInt(objectIndex / 5),
                0
              );
            } else if (this.type == "advanced_3") {
              // [] [] 
              // [] [] 
              // if first row
              if (shapeIndex < 2) {
                // shapeIndex % 2 to get 0 and 1 at the first two shapes
                tempObject.translate(
                  100 * (objectIndex % 5) + (shapeIndex % 2) * 500,
                  -100 * parseInt(objectIndex / 5),
                  0
                );
              // if second row
              } else {
                // ((shapeIndex - 2) % 2) to get 0 and 1 at the second two shapes
                tempObject.translate(0, -500, 0);
                tempObject.translate(
                  100 * (objectIndex % 5) + ((shapeIndex - 2) % 2) * 500,
                  -100 * parseInt(objectIndex / 5),
                  0
                );
              }
            // Any other type
            } else {
              tempObject.translate(
                100 * (objectIndex % 5),
                -100 * parseInt(objectIndex / 5),
                shapeIndex * 100
              );
            }
            // We store each spawned objects so that they will get merged
            spawnedObjects.push(tempObject);

            // Push only to materials if there is a color in allShapesColorArray
            if (this.allShapesColorArray[shapeIndex][objectIndex]){
              materials.push(new THREE.MeshPhongMaterial({ color: this.allShapesColorArray[shapeIndex][objectIndex]})); 
            }
          }                    
        });
      });
      
      // Check if there are objects spawned
      if (spawnedObjects.length) {
        // We merge all the shapes, the true argument is to keep having groups for materials
        var merged = BufferGeometryUtils.mergeGeometries(spawnedObjects, true);

        // If there is no materials, add the default one
        if (!materials.length){
          merged.groups.forEach((element) => {
              materials.push(defaultMaterial);
          });
        }

        // Here we handle each mode location and save it to mergedMesh
        if (this.type == "xMode") {
          // Handle the cloning in xMode
          // Here we have another BufferGeometry called mergedX which is rotated 90 (11/7 in radian)
          // and then both are merged to form the X shape
          merged.translate(-200, 0, -200);

          var mergedX = BufferGeometryUtils.mergeGeometries(spawnedObjects, true);
          mergedX.translate(0, 0, 0);
          mergedX.rotateY(11 / 7);

          mergedMesh = new THREE.Mesh(
            BufferGeometryUtils.mergeGeometries([mergedX, merged], true),
            defaultMaterial
          );

          mergedMesh.position.set(5, 135, 65);
        } else if (this.type == "cubeMode") {
          // Handle the cloning in cubeMode
          // Here we have another four BufferGeometrys called merged1..4 which is rotated 90 (11/7 in radian)
          // each time and then they are merged to form the cube shape
          merged.translate(-200, 0, -200);

          var merged2 = BufferGeometryUtils.mergeGeometries(spawnedObjects, true);
          merged2.translate(-200, 0, -200);
          merged2.rotateY(11 / 7);

          var merged3 = BufferGeometryUtils.mergeGeometries(spawnedObjects, true);
          merged3.translate(-200, 0, -200);
          merged3.rotateY(22 / 7);

          var merged4 = BufferGeometryUtils.mergeGeometries(spawnedObjects, true);
          merged4.translate(-200, 0, -200);
          merged4.rotateY(33 / 7);

          mergedMesh = new THREE.Mesh(
            BufferGeometryUtils.mergeGeometries([
              merged,
              merged2,
              merged3,
              merged4,
            ], true),
            defaultMaterial
          );

          mergedMesh.position.set(5, 135, 5);
        } else if (this.type == "CreativeHorizontalMode") {
          // Handle the CreativeHorizontalMode
          // Here we rotate the whole shape to match the horizontal orientation

          mergedMesh = new THREE.Mesh(merged, defaultMaterial);
          mergedMesh.rotation.set(-11 / 7, 0, 11 / 7);
          mergedMesh.position.set(-55, 15, 65);
        } else if (this.type.includes("verticalMode")) {
          // Handle the verticalMode
          // Here we count the number of layers
          // Add them to slices array then merge them into
          // one BufferGeometry

          var count = parseInt(this.type.slice(-1));
          var slices = [];
          for (let index = 0; index < count; index++) {
            merged = new BufferGeometryUtils.mergeGeometries(spawnedObjects, true);
            merged.translate(0, 0, -60 * (index + 1));
            slices.push(merged);
          }
          mergedMesh = new THREE.Mesh(
            BufferGeometryUtils.mergeGeometries(slices, true),
            defaultMaterial
          );
          mergedMesh.position.set(-55, 135, 10 * count - 17);
        } else if (this.type.includes("horizontalMode")) {
          // Handle the horizontalMode
          // Here we count the number of layers
          // Add them to slices array then merge them into
          // one BufferGeometry

          var count = parseInt(this.type.slice(-1));
          var slices = [];
          for (let index = 0; index < count; index++) {
            merged = new BufferGeometryUtils.mergeGeometries(spawnedObjects, true);
            merged.translate(0, 0, 60 * (index + 1));
            slices.push(merged);
          }
          mergedMesh = new THREE.Mesh(
            BufferGeometryUtils.mergeGeometries(slices, true),
            defaultMaterial
          );
          mergedMesh.position.set(-55, -3, -55);
          mergedMesh.rotation.set(-11 / 7, 0, 0);
        } else if (this.type == "advanced_1") {
          if (this.$route.params.type == "h") {
            mergedMesh = new THREE.Mesh(merged, materials);
            mergedMesh.position.set(-55, 135, -55);
          } else {
            mergedMesh = new THREE.Mesh(merged, materials);
            mergedMesh.rotation.set(-11/7, 0, 0);
            mergedMesh.position.set(-55, 15, -55);
          }
        } else if (this.type == "advanced_2") {
            mergedMesh = new THREE.Mesh(merged, materials);
            mergedMesh.position.set(-145, 135, 5);
        } else if (this.type == "advanced_3") {
          mergedMesh = new THREE.Mesh(merged, materials);
          mergedMesh.position.set(-145, 285, 5);
        } else if (this.type == "advanced_4") {
          mergedMesh = new THREE.Mesh(merged, materials);
          mergedMesh.position.set(-585, 135, 15);
        } else {
          // Handle the default/creativeVerticalMode

          mergedMesh = new THREE.Mesh(merged, defaultMaterial);
          mergedMesh.position.set(-55, 135, 5);
        }

        // We change the scale of the allShapesArray
        mergedMesh.scale.set(0.3, 0.3, 0.3);

        scene.add(mergedMesh);

        // This is the merged allShapesArray that can be exported to STL
        mesh = mergedMesh;
      }
    },

    removeObjects() {
      scene.remove(mergedMesh);
      spawnedObjects = [];
    },

    changeScale(weight) {
      this.removeObjects();
      spawnedScale = weight;
      this.spawnObjects();
    },

    onWindowResize() {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

      renderer.setSize(window.innerWidth, window.innerHeight);
    },

    animate() {
      requestAnimationFrame(this.animate);
      renderer.render(scene, camera);
    },

    exportASCII() {
      if (spawnedObjects.length){
        const result = exporter.parse(mesh);
        this.saveString(result, "NEOMI.stl");
      }
    },

    exportBinary() {
      if (spawnedObjects.length){
        const result = exporter.parse(mesh, { binary: true });
        this.saveArrayBuffer(result, "NEOMI.stl");
      }
    },

    save(blob, filename) {
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    },

    saveString(text, filename) {
      this.save(new Blob([text], { type: "text/plain" }), filename);
    },

    saveArrayBuffer(buffer, filename) {
      this.save(
        new Blob([buffer], { type: "application/octet-stream" }),
        filename
      );
    },
  },
  computed: {
    getData() {
      return this.$store.getters["groups/userDataForPrint"];
    },
  },
};
</script>

<style lang="scss" scoped></style>
